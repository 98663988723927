.delete-account-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  
    h2 {
      font-size: 1.8rem;
      color: #333;
      margin-bottom: 15px;
    }
  
    p {
      font-size: 1rem;
      color: #555;
      line-height: 1.6;
      margin-bottom: 10px;
  
      strong {
        color: #c0392b;
      }
    }
  
    ul {
      list-style-type: decimal;
      padding-left: 20px;
      margin-bottom: 20px;
  
      li {
        font-size: 1rem;
        color: #555;
        margin-bottom: 5px;
      }
    }
  
    .back-button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #2980b9;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #1a5276;
      }
    }
  }
  