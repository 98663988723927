@import '../../scss/index.scss';
.container_caracteristicas {
    background-color: #f0f0f0;
    .caracteristicas {
        font-family: $poppins;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 80px;
        h2 {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 20px;
            color: #181f3a;
        }
        p {
            font-size: 18px;
            line-height: 1.5;
            font-weight: lighter;
        }
    }
}