@import '../../scss/index.scss';
.banner {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, #ff0000, #0000ff);
        mix-blend-mode: screen;
        opacity: 0;
        animation: lights-flash 1s infinite;
        z-index: 2;
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/png/asfacFondo.png');
        background-size: cover;
        opacity: 1;
        z-index: 1;
    }
    .content {
        position: relative;
        z-index: 3;
        text-align: center;
        color: white;
        width: 50%;
        h1 {
            font-family: $poppins;
            font-size: 48px;
            margin-bottom: 20px;
            text-shadow: 3px 3px 4px $corporativo;
        }
        p {
            font-size: 24px;
            margin-bottom: 40px;
        }
        .cta-button {
            transition: 0.3s ease-in-out;
            font-size: 20px;
            font-weight: bold;
            background-color: #99201e;
            border-color: #99201e;
            text-decoration: none;
            color: white;
            padding: 10px;
            border-radius: 20px;
            cursor: pointer;
            &:hover {
                background-color: #ff0000;
            }
        }
    }
    @media (max-width: 768px) {
        .content {
            padding: 0 10%;
            width: 100%;
        }
    }
}

@keyframes lights-flash {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}