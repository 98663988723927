@import '../../scss/index.scss';
.reset_container {
    width: 100%;
    height: 100vh;
    background-image: radial-gradient(circle, rgba(24, 31, 58, .2), rgba(24, 31, 58, 1)), url("../../assets/jpg/justicia.png");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $poppins;
    .container {
        .row {
            .container_form {
                background-color: white;
                padding: 20px;
                box-shadow: 0 .9rem 1rem rgba(0, 0, 0, .4);
                border-radius: 10px;
                h2 {
                    font-weight: bold;
                    text-align: center;
                    color: $corporativo;
                }
                .btn {
                    width: 100%;
                    background-color: $corporativo;
                    border-color: $corporativo;
                    &:hover {
                        background-color: $corporativo;
                        border-color: $corporativo;
                    }
                }
            }
        }
    }
}