@import '../../scss/index.scss';
.download_container {
    background-image: radial-gradient(circle, rgba(24, 31, 58, .2), rgba(24, 31, 58, 1)), url("../../assets/jpg/justicia.png");
    background-size: cover;
    background-position: center;
    .download {
        font-family: $poppins;
        padding-top: 50px;
        .content {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .info {
                flex: 0 0 50%;
                color: #ffffff;
                display: flex;
                flex-direction: column;
                h2 {
                    font-size: 32px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 18px;
                    margin-bottom: 30px;
                    span {
                        font-weight: bold !important;
                        color: $corporativo;
                        text-shadow: 1px 1px 1px white;
                    }
                }
                .app-icons {
                    display: flex;
                    img {
                        width: 150px;
                        margin-right: 10px;
                    }
                }
            }
            .imagenes {
                img {
                    width: 100%;
                }
            }
            .floating-image {
                flex: 0 0 40%;
                animation: floatingAnimation 6s infinite ease-in-out;
                img {
                    width: 250px;
                }
            }
            @media (max-width: $media-breackpoint-up-lg) {
                align-items: flex-end;
            }
            @media (max-width: $media-breackpoint-up-md) {
                .info {
                    flex: 0 0 100%;
                    justify-content: center;
                    align-items: center;
                    h2 {
                        font-size: 25px;
                        text-align: center;
                    }
                    p {
                        font-size: 15px;
                        text-align: justify;
                    }
                }
            }
        }
    }
}