$font-light: #fff;
$font-grey: #a2a2a2;
$font-dark: #000;
$background-light: #fff;
$background-grey: #f0f2f5;
$background-grey-light: #fafafa;
$background-dark: #1f1f1f;
$background-success: #84284c1f;
$background-error: #ff00001f;
$border-grey: #484848;
$border-dark: #000;
$action: #0096f6;
$danger: #ed4956;
$success: #84b84c;
$happy: #ffc300;
$corporativo: #ec210f;
$rojo: #ec3c52;
$naranja:#f06e10;
$amarillo:#f7ad3b;
//Fuentes
$poppins: 'Poppins',
sans-serif;
//Responsive up size
$media-breackpoint-up-xs: 320px;
$media-breackpoint-up-sm: 576px;
$media-breackpoint-up-md: 767px;
$media-breackpoint-up-lg: 992px;
$media-breackpoint-up-xl: 1193px;