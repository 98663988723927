@import '../../scss/index.scss';
.container_faltas_admin {
    background-color: white;
    .faltas-administrativas {
        text-align: center;
        padding-top: 80px;
        padding-bottom: 80px;
        h2 {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 20px;
            font-family: $poppins;
            color: #181f3a;
        }
        .row {
            .col-lg-3,
            .col-md-6,
            .col-sm-6,
            .col-12 {
                .falta-item {
                    height: 200px;
                    background-color: #181f3a;
                    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 20px;
                    width: 200px;
                    padding: 10px;
                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: contain;
                        margin-bottom: 10px;
                        color: #181f3a;
                    }
                    p {
                        font-size: 13px;
                        font-family: $poppins;
                        color: white;
                    }
                    &:hover {
                        transform: scale(1.01);
                        background-color: #232d55;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.8);
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                        padding: 0px;
                        margin: 0px;
                        margin-top: 20px;
                        p {
                            width: 70%;
                            padding: 10px;
                        }
                    }
                }
            }
        }
        .faltas-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}