@import '../../scss/index.scss';
.container_reset {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .reset {
        width: 350px;
        border-radius: 10px;
        background-color: white;
        box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
        .container_image {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            img {
                width: 150px;
            }
            .title {
                font-weight: bold;
                font-size: 18px;
                color: $corporativo;
            }
            .container_form {
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 10px;
                width: 100%;
                font-family: $poppins;
                button {
                    width: 100%;
                }
            }
            .exito {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: $poppins;
                .exito_texto {
                    font-size: 20px;
                    color: $corporativo;
                }
                .exito_sub {
                    font-size: 14px;
                    text-align: center;
                }
                button {
                    width: 100%;
                }
            }
        }
    }
}