@import '../../scss/index.scss';
.header {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    transition: 0.3s ease-in;
    &.scrolled {
        background-color: #181f3a;
    }
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        .navbar-brand {
            display: flex;
            align-items: center;
            .menu-icon {
                width: 40px;
                height: 40px;
                cursor: pointer;
                margin-right: 10px;
            }
        }
        .navigation {
            display: flex;
            list-style-type: none;
            margin: 0;
            padding: 0;
            .nav-item {
                margin-left: 20px;
                font-family: $poppins;
                color: #fff;
                text-decoration: none;
                font-size: 13px;
                transition: 0.3s ease-in-out;
                text-transform: uppercase;
                cursor: pointer;
                &:hover {
                    color: #ff0000;
                }
            }
        }
        .sideBar {
            display: none;
            svg {
                width: 25px;
                height: auto;
                fill: white;
            }
        }
    }
    @media (max-width: $media-breackpoint-up-lg) {
        background-color: #181f3a;
        .navbar {
            .navigation {
                opacity: 0%;
                width: 40%;
                left: -40%;
                position: absolute;
                top: 70px;
                background-color: #181f3a;
                flex-direction: column;
                height: 100vh;
                transition: 0.5s ease-in;
                justify-content: flex-start;
                .nav-item {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-family: $poppins;
                    color: #fff;
                    text-decoration: none;
                    font-size: 16px;
                    transition: 0.3s ease-in-out;
                    text-transform: uppercase;
                }
            }
            .nav__active {
                transform: translateX(0);
                transition: 0.5s ease-in-out;
                opacity: 100%;
                left: 0%;
            }
            .sideBar {
                display: block;
            }
            @media (max-width: $media-breackpoint-up-sm) {
                .navigation {
                    width: 60%;
                }
            }
        }
    }
}