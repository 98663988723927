@import '../../scss/index.scss';
.explicacion {
    background-color: #f5f5f5;
    padding: 40px 0;
    h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;
        font-family: $poppins;
        text-align: center;
        color: #181f3a;
    }
    .steps {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 40px;
        .step {
            background-color: white;
            text-align: center;
            width: 100%;
            padding: 40px 20px;
            transition: 0.3s ease-in-out;
            margin-bottom: 20px;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
            img {
                width: 100%;
                max-height: 200px;
                object-fit: cover;
                margin-bottom: 10px;
            }
            h3 {
                font-size: 19px;
                font-weight: bold;
                margin-bottom: 10px;
                font-family: $poppins;
                color: #181f3a;
                text-align: start;
                text-transform: uppercase;
            }
            p {
                font-size: 16px;
                font-family: $poppins;
                color: #4f4f4f;
                text-align: start;
            }
            &:hover {
                transform: translateY(-5px);
            }
            @media (max-width: 575px) {
                width: 100%;
                padding: 20px;
                h3 {
                    font-size: 15px;
                }
                p {
                    font-size: 13px;
                }
            }
        }
    }
}