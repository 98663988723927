@import '../../scss/index.scss';
.footer_container_option {
    padding-top: 20px;
    background-color: #181f3a;
    .container {
        .row {
            .logo {
                .logo_descripcion {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 65px;
                        height: auto;
                    }
                    p {
                        margin-left: 10px;
                        color: white;
                        margin-bottom: 0px;
                        font-family: $poppins;
                        font-size: 15px;
                    }
                }
                .descripcion {
                    margin-top: 10px;
                    p {
                        color: white;
                        margin-bottom: 0px;
                        font-family: $poppins;
                        font-size: 11px;
                        text-align: justify;
                    }
                }
            }
            .servicios_container {
                h5 {
                    color: $corporativo;
                    font-family: $poppins;
                    font-size: 15px;
                }
                p {
                    color: white;
                    font-size: 12px;
                    margin-bottom: 5px;
                    font-family: $poppins;
                    margin-bottom: 10px;
                    font-weight: bold;
                }
                .row {
                    .servicios_lista {
                        li {
                            color: white;
                            font-size: 11px;
                            margin-bottom: 5px;
                            font-family: $poppins;
                        }
                    }
                }
            }
            .contacto {
                h5 {
                    color: $corporativo;
                    font-family: $poppins;
                    font-size: 15px;
                }
                p {
                    color: white;
                    font-size: 12px;
                    margin-bottom: 5px;
                    font-family: $poppins;
                    margin-bottom: 10px;
                    font-weight: bold;
                }
                .social_media_container {
                    align-items: center;
                    display: flex;
                    margin-bottom: 10px;
                    a {
                        text-decoration: none;
                        cursor: pointer;
                        svg {
                            padding: 5px;
                            width: 30px;
                            height: auto;
                            margin-right: 15px;
                            color: white;
                            fill: white;
                            transition: 0.2s ease-in-out;
                            &:hover {
                                background-color: $corporativo;
                            }
                        }
                    }
                }
                .container_tiendas {
                    display: flex;
                    flex-direction: column;
                    img {
                        width: 119px;
                        height: 52px;
                    }
                }
            }
            .asfac_copi {
                margin-top: 10PX;
                font-size: 15px;
                color: white;
                font-family: $poppins;
                text-align: center;
                border-top: 3px solid $corporativo;
                p {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}