@import '../../scss/index.scss';
.container_politica {
    font-family: 'Poppins';
    img {
        width: 100%;
    }
    h1 {
        font-size: 30px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #010528;
    }
    p {
        font-size: 13px;
    }
}